.center,
.cont1,
.cont2 {
  background-color: #1b1b28;
  border-radius: 10px;
}
.sel,
.whitefont {
  color: #fff;
}
.converbtn,
.drop,
.maxbtn,
.sel,
.sendbtn {
  transition: 0.3s;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cont1,
.cont2 {
  padding: 20px;
  height: 100%;
  border-width: 1px;
  border-color: rgb(255, 255, 255, 0.3);
  border-style: solid;
}
.cont1 {
  max-width: 1000px;
  min-height: 180px;
  border-width: 1px;
  border-color: rgb(255, 255, 255, 0.3);
  border-style: solid;
}
.sel {
  background-color: #383848;
  border-color: #383848;
  cursor: pointer;
  text-align-last: center;
  background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
    linear-gradient(135deg, #fff 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}
.drop1 {
  background-color: #383848;
  color: white;
}
.drop1:hover {
  background-color: #4c4c5a;
  color: white;
  cursor: pointer;
}
.sel:focus {
  background-color: #383848;
  color: white;
}
.converbtn,
.sel:hover {
  border-color: #7b98f9;
  color: #7b98f9;
}
.convert {
  max-width: 20px;
  max-height: 20px;
}
.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
.converbtn {
  padding: 5px;
  background-color: #7b98f9;
}
.drop,
.inp1 {
  cursor: pointer;
  border-color: #383848;
  border-width: 0;
  transition: 0.3s;
}
.inp1:focus {
  border-color: #a3b6f8;
}
.trans_cont {
  background-color: #272738;
  border-radius: 5px;
  padding: 10px;
}
.btnbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.maxbtn {
  background-color: #7b98f9;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 30px;
  width: 200px;
  font-size: 14px;
  font-weight: 700;
}
.maxbtn:hover {
  background-color: #a3b6f8;
}
.drop,
.inp1,
.sendbtn {
  background-color: #383848;
  height: 50px;
  color: #fff;
  border-radius: 5px;
}
.sendbtn:hover {
  border-color: #fff;
}
.inp1:hover {
  border-width: 1px;
  border-color: #a3b6f8;
}
.inp1:focus {
  cursor: normal;
}
.converbtn:hover {
  background-color: #a3b6f8;
}
.txhist td,
.txhist th {
  background-color: #1b1b28;
}
.txhist {
  margin: 10px;
}
.balancecont {
  background-color: #383848;
  border-radius: 5px;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
