.cont1 {
  background-color: #272738;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  max-width: 800px;
}
.bluetext {
  color: #a3b6f8;
}
.logo {
  margin-right: 20px;
}
.side_text {
  text-decoration: none;
  font-size: 20px;
  text-align: center;
  padding: 5px;
  color: #fff;
}
.nav_1 {
  display: flex;
  width: 300px;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}
.nav_1:hover {
  background: #131313;
}
#sidebar-wrapper,
.list-group {
  background-color: #1b1b28;
}
.content {
  font-size: large;
}
#wrapper {
  overflow-x: hidden;
}
#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -20rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  margin-right: 10px;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  color: #fff;
}
#sidebar-wrapper .list-group {
  width: 15rem;
}
#page-content-wrapper {
  min-width: 100vw;
}
.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}
.togglebtn {
  transition: 0.3s;
  background-color: "#383848";
}
.topcont {
  z-index: -1000;
}
.togglebtn:hover {
  border-color: #a3b6f8;
}
@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  .sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -20rem;
  }
}
