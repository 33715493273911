.sel1,
option {
  color: #fff;
  border-color: #383848;
  cursor: pointer;
}
.inp1,
.sel1,
option {
  transition: 0.3s;
  text-align-last: center;
}
.main_cont {
  background-color: #1b1b28;
  padding: 30px;
  border-radius: 5px;
  border-width: 1px;
  border-color: rgb(255, 255, 255, 0.3);
  border-style: solid;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 20px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.cont_s {
  background-color: #272738;
  border-radius: 10px;
}
.sel1,
.sel1:focus,
option {
  background-color: #383848;
}
.sel1 {
  background-image: linear-gradient(45deg, transparent 50%, #fff 50%),
    linear-gradient(135deg, #fff 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}
option {
  height: 30px;
}
.sel1:hover {
  border-color: #7b98f9;
}
.table1 td,
.table1 th {
  background-color: #272738;
}
.inp1,
.inp1:focus {
  background-color: #383848;
  color: #fff;
}
.table1 {
  border-radius: 5px;
  padding: 0;
}
.inp1 {
  border-color: #383848;
}
.inp1:hover {
  border-color: #7b98f9;
}
